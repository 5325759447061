import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { API } from "../baseUrl/baseUrl";
import {
  getpermissionsToRoleInterface,
  permissionsToRoleInterface,
} from "../interfaces/interfaces";

const getPermission = async (roleID: number) => {
  return await API.get(`/permission_to_role/${roleID}`);
};

const changePermission = async (roleID: number, data: any) => {
  return await API.put(`/permission_to_role/${roleID}`, data);
};

const initialState: getpermissionsToRoleInterface = {
  permissions: [],
  status: "idle",
};

export const getPermissionToRoleListAsync = createAsyncThunk(
  "getPermissionListSlicer/getPermissionList",
  async (roleID: number) => {
    try {
      const response = await getPermission(roleID);
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        return err.response?.data;
      }
    }
  }
);

export const changePermissionListAsync = createAsyncThunk(
  "changePermissionList",
  async (roleID: number, data: any) => {
    try {
      const response = await changePermission(roleID, data);
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        return err.response?.data;
      }
    }
  }
);

export const getPermissionListSlice = createSlice({
  name: "getPermissionListSlicer",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPermissionToRoleListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getPermissionToRoleListAsync.fulfilled,
        (state, action: PayloadAction<Array<permissionsToRoleInterface>>) => {
          state.permissions = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getPermissionToRoleListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default getPermissionListSlice.reducer;
