import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "./Auth";
import icon from "../images/icon.png";
import { RiKeyLine, RiUserLine } from "react-icons/ri";
import jwtDecode from "jwt-decode";
import { API } from "../baseUrl/baseUrl";
import { useNavigate } from "react-router-dom";
import SetCookie from "../cookies/setCookie";
import STIL from "../images/STIL.png";

const Login = () => {
  const [userData, setUserData] = useState({ email: "", password: "" });
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const { signin } = useAuth();

  const userRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const resp = await API.post(`/signin`, userData);
      const token = resp.data[1];
      const decode: any = jwtDecode(token);
      API.get(`/permission_list/${decode.id}`);
      SetCookie("accessCookie", token);
      signin(token, () => {
        navigate("/");
      });
    } catch (err: any) {
      if (!err?.response) {
        setErrMsg("Server cavab vermir");
      } else if (err.response?.status === 400) {
        setErrMsg(err?.response.data);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Hesaba daxil olmaq mümkün deyil");
      }
      console.log(err);
    }
  };

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);
  return (
    <>
      <div className="all-sections-login">
        <section className="logo-login">
          <img className="stil-logo" src={STIL} alt="logo"></img>
        </section>

        <section className="form-login">
          <h3 className="login-header">Hesaba daxil olun</h3>
          <img className="icon-avatar" src={icon} alt="icon"></img>

          <form onSubmit={handleSubmit}>
            <div className="user-div-login">
              <input
                type="email"
                className="user-input-login"
                placeholder="e-mail daxil edin"
                ref={userRef}
                autoComplete="off"
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
                value={userData.email}
                required
              />
              <span className="icons-input-login">
                <RiUserLine />
              </span>
            </div>

            <div className="pwd-div-login">
              <input
                type="password"
                className="pwd-input-login"
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
                value={userData.password}
                required
                placeholder="şifrənizi daxil edin"
              ></input>
              <span className="icons-input-login">
                <RiKeyLine />
              </span>
            </div>
            <button type="submit">Daxil ol</button>
          </form>

          <p className="error-p" aria-live="assertive">
            {errMsg}
          </p>
        </section>
      </div>
    </>
  );
};

export default Login;
