import React from "react";
import RolesList from "./RolesList";
import CreateRole from "./CreateRole";

const RolePage = () => {
  return (
    <div>
      <div>
        <CreateRole />
        <RolesList />
      </div>
    </div>
  );
};

export default RolePage;
