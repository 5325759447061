import React, { useState } from "react";
import { Button, Modal } from "rsuite";
import { getPermissionToRoleListAsync } from "../../../generalUsesReducers/permissionToRoleSlicer";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { VscKey } from "react-icons/vsc";
import { API } from "../../../baseUrl/baseUrl";

interface PermissionListType {
  permissionID: number;
  access: boolean;
}

export default function PermissionPage({
  roleID,
  roleName,
}: {
  roleID: number;
  roleName: string;
}) {
  const dispatch = useAppDispatch();

  const changePermissionListAsync = async () => {
    try {
      API.put(`/permission_to_role/${roleID}`, permissionList);
    } catch (error: any) {
      console.log(error);
    }
  };

  const { permissions } = useAppSelector(
    (state) => state.permissionsToRoleList
  );

  var [permissionList, setPermissionList] = useState<Array<PermissionListType>>(
    []
  );

  const permissionListHandler = ([permissionID, access]: any) => {
    const index = permissionList.findIndex(
      (p) => p.permissionID === permissionID
    );
    if (permissionList.length === 0) {
      permissionList.push({
        permissionID: permissionID,
        access: access,
      });
      setPermissionList(permissionList);
    } else {
      if (index !== -1) {
        permissionList.splice(index, 1);
      } else {
        permissionList.push({
          permissionID: permissionID,
          access: access,
        });
      }
      setPermissionList([...permissionList]);
    }
  };

  const [show, setShow] = useState(false);

  const close = () => {
    permissionList = [];
    setPermissionList(permissionList);
    setShow(false);
    dispatch(getPermissionToRoleListAsync(roleID));
  };

  const open = async () => {
    await dispatch(getPermissionToRoleListAsync(roleID));
    setShow(true);
  };
  const [desc, setDesc] = useState<string>();

  const describeMeans = (perm: string) => {
    if (perm.includes("SATIŞ ET")) {
      setDesc("İstifadəçiyə satış həyata keçirmək səlahiyyəti verir");
    } else if (perm.includes("ADMİN SƏLAHİYYƏTLƏRİ")) {
      setDesc(
        "İstifadəçi və kateqoriyaların yaradılması, istifadəçiyə kateqoriya və mağazaların təyin edilməsi"
      );
    } else if (perm.includes("FAKTURALARIN İDARƏ EDİLMƏSİ")) {
      setDesc(
        "Fakturanın yaradılması (alış əməliyyatı), silinməsi və yenilənməsi"
      );
    } else if (perm.includes("MƏHSULLARIN İDARƏ EDİLMƏSİ")) {
      setDesc("Yeni məhsul, çeşid, kod, ölçü və mağaza/anbar yaratmaq");
    } else if (perm.includes("SATIŞ İCAZƏLƏRİ")) {
      setDesc("Edilmiş satışın silinməsi, yenilənməsi");
    } else if (perm.includes("STOK İDARƏSİ")) {
      setDesc(
        "Stokdan malın silinməsi, əlavə edilməsi, yenilənməsi, stokun transferi"
      );
    } else if (perm.includes("STOKLARA BAX")) {
      setDesc("Stok siyahısına baxmaq");
    } else {
      setDesc("bunu bilmirem");
    }
  };

  return (
    <div>
      <div>
        <span
          onClick={() => {
            open();
          }}
        >
          <VscKey className="user-list-key-icon" />
        </span>
        <Modal open={show} onClose={close} size="xs">
          <Modal.Header>
            <Modal.Title>
              {roleName?.toUpperCase() + " üçün icazələr"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="accesses">
              {permissions?.map((permission, index) => (
                <div key={index}>
                  <input
                    id={permission.permission}
                    onChange={(event) => {
                      permissionListHandler([
                        permission.permissionID,
                        event.target.checked,
                      ]);
                    }}
                    type="checkbox"
                    defaultChecked={Boolean(Number(permission.access))}
                  />
                  <label
                    onMouseOver={() => {
                      describeMeans(permission.permission);
                    }}
                    onMouseOut={() => {
                      setDesc("");
                    }}
                    htmlFor={permission.permission}
                    title={desc}
                  >
                    {permission.permission}
                  </label>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              appearance="primary"
              onClick={() => {
                changePermissionListAsync();
                close();
              }}
            >
              Təsdiq et
            </Button>
            <Button onClick={close} appearance="subtle">
              Geri qayıt
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
