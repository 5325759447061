import { Message, toaster } from "rsuite";
import { AlertProps } from "./alert.Interface";

export const Alert = ({ type, title, placement, duration }: AlertProps) => {
  toaster.push(
    <Message showIcon type={type}>
      {title}
    </Message>,
    { placement, duration }
  );
};
