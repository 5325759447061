import React from "react";
import { getWarehouseListAsync } from "../../../generalUsesReducers/getWarehouseSlice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { FaTrashRestore } from "react-icons/fa";
import { API } from "../../../baseUrl/baseUrl";
import { Alert } from "../../../alert/alert";
const Warehouse = () => {
  const dispatch = useAppDispatch();
  const { warehouse_ } = useAppSelector((state) => state.warehouseList);
  const deletewh = async (id: number) => {
    try {
      const response = await API.delete(`/warehouse/${id}`);
      dispatch(getWarehouseListAsync());
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error.response?.data,
        placement: "bottomCenter",
        duration: 5000,
      });
      console.log(error);
    }
  };
  return (
    <div className="wh-div">
      <h3>Mağaza / Anbarlar: </h3>
      <table className="tablo-user-list" style={{ margin: "0 0.5em" }}>
        <tbody>
          {warehouse_?.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.warehouse}</td>
              <td>
                <button
                  onClick={() => deletewh(item.id)}
                  className="role-wh-delete-btn"
                >
                  <FaTrashRestore className="delete-icons" title="sil" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Warehouse;
