import React, { useState, useEffect } from "react";
import CreateProduct from "./components/NewProduct/CreateProduct";
import { searchParametersInterface } from "../../interfaces/interfaces";
import { useAppDispatch } from "../../store/hooks";
import { HiChevronDoubleRight } from "react-icons/hi";
import { controlLoad } from "../../generalUsesReducers/Loading";
import { getProdListAsync } from "../../generalUsesReducers/stockSlice";
import TableSearche from "./components/Table/TableSearche";
import TableAlish from "./components/Table/TableAlish";
import { useAppSelector } from "../../store/hooks";

const Alishpage = () => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<searchParametersInterface>({
    mehsulID: 0,
    cheshidID: 0,
    kodID: 0,
    warehouseID: 0,
    barkod: "",
    take: 10,
    skip: 1,
  });
  const [showContainer, setShowContainer] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const getProdList = async () => {
      dispatch(controlLoad(false));
      try {
        dispatch(controlLoad(true));
        await dispatch(getProdListAsync(search));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(controlLoad(false));
      }
    };
    getProdList();
  }, [search]);

  // permissions
  const { permissionsList } = useAppSelector((state) => state.permissionsList);
  const permissions = permissionsList.map((item) => item.permission);
  return (
    <>
      <div className="alish-main-container-flex">
        <div>
          <CreateProduct showContainer={showContainer} search={search} />
        </div>
        <div style={{ display: "flex" }}>
          {permissions.includes("STOK İDARƏSİ") ? (
            <div
              className={
                showContainer ? "top-to-bottom-btn" : "top-to-bottom-btn close"
              }
            >
              <button onClick={() => setShowContainer(!showContainer)}>
                <span>
                  {showContainer ? "Məhsul yarat" : <HiChevronDoubleRight />}
                </span>
              </button>
            </div>
          ) : null}
          <div className="alish-rigthside-table">
            <div>
              <TableSearche
                searchParameters={search}
                setSearchParameters={setSearch}
                setPage={setPage}
              />
              <TableAlish
                search={search}
                setSearch={setSearch}
                page={page}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Alishpage;
