import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API } from "../baseUrl/baseUrl";
import { rolesInterface, getRolesInterface } from "../interfaces/interfaces";

export const getRole = async () => {
  return await API.get(`/role`);
};

export const getRoleListAsync = createAsyncThunk(
  "getProdListSlicer/getProductList",
  async () => {
    try {
      const response = await getRole();
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

const initialState: getRolesInterface = {
  roles: [],
  status: "idle",
};
export const getRolesListSlice = createSlice({
  name: "getProdListSlicer",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getRoleListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getRoleListAsync.fulfilled,
        (state, action: PayloadAction<Array<rolesInterface>>) => {
          state.roles = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getRoleListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default getRolesListSlice.reducer;
