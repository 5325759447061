import React, { useState } from "react";
import { Modal, Button } from "rsuite";
import { TextField } from "@mui/material";
import { API } from "../../../baseUrl/baseUrl";
import { Alert } from "../../../alert/alert";
import { getFakturaListAsync } from "../../../generalUsesReducers/getFakturaSlice";
import { useAppDispatch } from "../../../store/hooks";
import { searchItemsFakturaInterface } from "../../../interfaces/interfaces";

const EditModal = ({
  rowData,
  searchItems,
}: {
  rowData: any;
  searchItems: searchItemsFakturaInterface;
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { id, faktura, say, maya_deyeri, tarix } = rowData;
  const [editData, setEditData] = useState({
    id: Number(),
    faktura: "",
    say: Number(),
    maya_deyeri: Number(),
    tarix: "",
  });

  const handleOpenEdit = () => {
    setOpen(true);
    setEditData({
      id: Number(id),
      faktura,
      say: Number(say),
      maya_deyeri: Number(maya_deyeri),
      tarix,
    });
  };
  const handleClose = () => setOpen(false);

  const sendNewData = async () => {
    try {
      const response = await API.patch(`/faktura`, editData);
      setTimeout(() => {
        dispatch(getFakturaListAsync(searchItems));
      }, 100);
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error.response.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error);
    }
  };
  return (
    <>
      <Button
        appearance="link"
        onClick={() => {
          handleOpenEdit();
        }}
      >
        Yenilə
      </Button>
      <div>
        <Modal open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <div>
                <h3>Düzəliş</h3>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />
            <div className="textfield" style={{ textAlign: "center" }}>
              <form
                id="modal-textfield"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleClose();
                  sendNewData();
                }}
              >
                <TextField
                  autoComplete="off"
                  required
                  style={{ margin: "0.5em 3em", width: "18em" }}
                  label="Faktura"
                  variant="outlined"
                  size="small"
                  value={editData.faktura}
                  onChange={(e) => {
                    setEditData({ ...editData, faktura: e.target.value });
                  }}
                />

                <TextField
                  required
                  autoComplete="off"
                  style={{ margin: "0.5em 3em", width: "18em" }}
                  label="Maya dəyəri"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={editData.maya_deyeri}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      maya_deyeri: Number(e.target.value),
                    });
                  }}
                />
                <TextField
                  required
                  autoComplete="off"
                  style={{ margin: "0.5em 3em", width: "18em" }}
                  label="Say"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={editData.say}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      say: parseFloat(e.target.value),
                    });
                  }}
                />
                <TextField
                  required
                  style={{ margin: "0.5em 3em", width: "18em" }}
                  label=""
                  type="date"
                  variant="outlined"
                  size="small"
                  value={editData.tarix.slice(0, 10)}
                  onChange={(e) => {
                    setEditData({ ...editData, tarix: e.target.value });
                  }}
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button form="modal-textfield" type="submit" appearance="primary">
              Təsdiq et
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Geri qayıt
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditModal;
