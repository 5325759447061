import React from "react";

const TarixcheFooter = (props: any) => {
  const { dailyCash, special, endirim } = props;
  return (
    <div
      style={{
        textAlign: "center",
        fontFamily: "Calibri",
        fontSize: "1.2em",
        color: "black",
      }}
    >
      <h5>Seçilmiş dövr üçün: </h5>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ marginRight: "2em" }}>
          Satış məbləği: <b> {`${Math.round(dailyCash * 100) / 100} AZN`}</b>
        </div>
        {special ? (
          <div style={{ marginRight: "2em" }}>
            Xüsusi kassa: <b>{`${Math.round(special * 100) / 100} AZN`}</b>
          </div>
        ) : null}
        <div style={{ marginRight: "2em" }}>
          Endirim məbləği: <b> {`${Math.round(endirim * 100) / 100} AZN`}</b>
        </div>
      </div>
      <div style={{ marginRight: "2em" }}>
        Yekun məbləğ:
        <b>{` ${
          Math.round((dailyCash - endirim - special) * 100) / 100
        } AZN`}</b>
      </div>
    </div>
  );
};

export default TarixcheFooter;
