import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import { getMehsulInterface, mehsulInterface } from "../interfaces/interfaces";
import { AxiosError } from "axios";
import { Alert } from "../alert/alert";

const initialmehsulvalue: getMehsulInterface = {
  mehsullar: [],
  status: "idle",
};

export const getMehsulAsync = createAsyncThunk(
  "mehsulList/getMehsul",
  async () => {
    try {
      const response = await API.get(`/mehsul`);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        Alert({
          type: "error",
          title: error.response?.data,
          placement: "bottomCenter",
          duration: 5000,
        });
        return error.response?.data;
      }
    }
  }
);

const mehsulSlice = createSlice({
  name: "mehsulList",
  initialState: initialmehsulvalue,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMehsulAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getMehsulAsync.fulfilled,
        (state, action: PayloadAction<Array<mehsulInterface>>) => {
          state.mehsullar = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getMehsulAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});
export const mehsulList = (state: RootState) => state.mehsulList.mehsullar;
export default mehsulSlice.reducer;
