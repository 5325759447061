import React, { useState } from "react";
import { Button, Modal } from "rsuite";
import { useAppSelector } from "../../../store/hooks";
import { API } from "../../../baseUrl/baseUrl";
import { Alert } from "../../../alert/alert";
import { BsPinAngle } from "react-icons/bs";
import { FaTrashRestore } from "react-icons/fa";
import { VscKey } from "react-icons/vsc";
import {
  warehouseLinkedInterface,
  roleLinkedInterface,
} from "../../../interfaces/interfaces";

export default function ModalUser({
  email,
  id,
}: {
  email: string;
  id: number;
}) {
  const { roles } = useAppSelector((state) => state.roleList);
  const { warehouse_ } = useAppSelector((state) => state.warehouseList);
  const [show, setShow] = useState(false);
  const [userToRole, setUserToRole] = useState({
    roleID: Number(),
    userID: id,
  });

  const [userToWarehouse, setUserToWarehouse] = useState({
    userID: id,
    warehouseID: Number(),
  });
  const [roleLinked, setRoleLinked] = useState<roleLinkedInterface>();
  const [warehouseLinked, setWarehouseLinked] =
    useState<warehouseLinkedInterface>();

  const getLinkedData = async () => {
    const roles = await API(`/role_to_user/${id}`);
    setRoleLinked(roles.data);
    const warehouse = await API(`/user_to_warehouse/${id}`);
    setWarehouseLinked(warehouse.data);
  };

  const close = () => {
    setShow(false);
  };

  const open = async () => {
    setShow(true);
    getLinkedData();
  };

  //role_to_user
  const linktoRole = async () => {
    try {
      const response = await API.post(`/role_to_user`, userToRole);
      getLinkedData();
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      if (error.response.data.includes("Duplicate entry")) {
        Alert({
          type: "error",
          title: "Əvvəlcə mövcud kateqoriyanı silin",
          placement: "topCenter",
          duration: 5000,
        });
      } else if (error.response.data.includes("constraints: isPositive")) {
        Alert({
          type: "error",
          title: "Əvvəlcə lazım olan kateqoriyanı seçin",
          placement: "topCenter",
          duration: 5000,
        });
      } else {
        Alert({
          type: "error",
          title: error.response.data,
          placement: "topCenter",
          duration: 5000,
        });
      }
      console.log(error);
    }
  };
  const linktoWarehouse = async () => {
    try {
      const response = await API.post(`/user_to_warehouse`, userToWarehouse);
      getLinkedData();
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      if (error.response.data.includes("Duplicate entry")) {
        Alert({
          type: "error",
          title: "Əvvəlcə mövcud mağazanı silin",
          placement: "topCenter",
          duration: 5000,
        });
      } else if (error.response.data.includes("constraints: isPositive")) {
        Alert({
          type: "error",
          title: "Əvvəlcə lazım olan mağazanı seçin",
          placement: "topCenter",
          duration: 5000,
        });
      } else {
        Alert({
          type: "error",
          title: error.response.data,
          placement: "topCenter",
          duration: 5000,
        });
      }
      console.log(error);
    }
  };
  return (
    <div>
      <div>
        <span
          onClick={() => {
            open();
          }}
        >
          <VscKey className="user-list-key-icon" />
        </span>
        <Modal open={show} onClose={close} size="sm">
          <Modal.Header>
            <Modal.Title>{email}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="user-modal">
              <div className="first-div">
                <h4>
                  Mövcud kateqoriya:
                  <span>
                    {roleLinked?.role_?.role
                      ? roleLinked?.role_?.role.toUpperCase()
                      : "yoxdur"}
                  </span>
                </h4>
                {roleLinked?.role_?.role && id !== 1 ? (
                  <button
                    className="role-wh-delete-btn"
                    onClick={async () => {
                      await API.delete(`/role_to_user/${roleLinked.id}`);
                      getLinkedData();
                    }}
                  >
                    <FaTrashRestore
                      className="delete-icons modal-icons"
                      title="sil"
                    />
                  </button>
                ) : null}
              </div>
              <div>
                <select
                  onChange={(e) => {
                    e.target.value !== "kateqoriyalar"
                      ? setUserToRole({
                          ...userToRole,
                          roleID: Number(e.target.value),
                        })
                      : setUserToRole({
                          ...userToRole,
                          roleID: 0,
                        });
                  }}
                >
                  <option value="kateqoriyalar"> Kateqoriyalar </option>
                  {roles?.map((role) => (
                    <option value={role.id} key={role.id}>
                      {role.role?.toUpperCase()}
                    </option>
                  ))}
                </select>
                <button className="role-wh-delete-btn" onClick={linktoRole}>
                  <BsPinAngle
                    title="hesaba əlavə et"
                    className="pin-icons modal-icons"
                  />
                </button>
              </div>
              <div className="first-div">
                <h4>
                  Mağaza:
                  <span>
                    {warehouseLinked?.warehouseToUser?.warehouse
                      ? warehouseLinked?.warehouseToUser?.warehouse
                      : "yoxdur"}
                  </span>
                </h4>

                {warehouseLinked?.warehouseToUser?.warehouse ? (
                  <button
                    className="role-wh-delete-btn"
                    onClick={async () => {
                      await API.delete(
                        `/user_to_warehouse/${warehouseLinked.id}`
                      );
                      getLinkedData();
                    }}
                  >
                    <FaTrashRestore
                      className="delete-icons modal-icons"
                      title="sil"
                    />
                  </button>
                ) : null}
              </div>
              <div>
                <select
                  onChange={(e) => {
                    e.target.value !== "magaza"
                      ? setUserToWarehouse({
                          ...userToWarehouse,
                          warehouseID: Number(e.target.value),
                        })
                      : setUserToWarehouse({
                          ...userToWarehouse,
                          warehouseID: 0,
                        });
                  }}
                >
                  <option value="magaza"> Mağazalar </option>
                  {warehouse_?.map((warehouse) => (
                    <option value={warehouse.id} key={warehouse.id}>
                      {warehouse.warehouse}
                    </option>
                  ))}
                </select>
                <button
                  className="role-wh-delete-btn"
                  onClick={() => {
                    linktoWarehouse();
                  }}
                >
                  <BsPinAngle
                    title="hesaba əlavə et"
                    className="pin-icons modal-icons"
                  />
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={close} appearance="subtle">
              Bağla
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
