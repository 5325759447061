import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import { getKodInterface, kodByCheshidID } from "../interfaces/interfaces";

const getProdinitialState: getKodInterface = {
  kod_: [],
  status: "idle",
};

export const getKodListAsync = createAsyncThunk<Array<kodByCheshidID>, number>(
  "getKodListAsync/getKodListSlicer",
  async (kodID) => {
    try {
      const response = await API.get(`/cheshid_to_kod/${kodID}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getKodListSlice = createSlice({
  name: "getKodListSlicer",
  initialState: getProdinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getKodListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getKodListAsync.fulfilled,
        (state, action: PayloadAction<Array<kodByCheshidID>>) => {
          state.kod_ = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getKodListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const KodList = (state: RootState) => state.kodList.kod_;

export default getKodListSlice.reducer;
