import React, { useState } from "react";
import { Modal, Button } from "rsuite";
import { TextField } from "@mui/material";
import { useAppDispatch } from "../../../../store/hooks";
import { API } from "../../../../baseUrl/baseUrl";
import { getProdListAsync } from "../../../../generalUsesReducers/stockSlice";
import { searchParametersInterface } from "../../../../interfaces/interfaces";
import { Alert } from "../../../../alert/alert";

interface EditType {
  id: number;
  kampaniya_endirimi: number;
  qiymet: number;
  barkod: string;
}

const EditStockModal = ({
  rowData,
  search,
}: {
  rowData: any;
  search: searchParametersInterface;
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { id, kampaniya_endirimi, qiymet, barkod } = rowData;
  const [editData, setEditData] = useState<EditType>({
    id: Number(),
    kampaniya_endirimi: Number(),
    qiymet: Number(),
    barkod: "",
  });

  const handleOpenEdit = async () => {
    setOpen(true);
    setEditData({
      id: Number(id),
      kampaniya_endirimi: Number(kampaniya_endirimi),
      qiymet: Number(qiymet),
      barkod,
    });
  };
  const handleClose = () => setOpen(false);

  const sendNewData = async () => {
    try {
      const response = await API.patch(`/stock`, editData);
      dispatch(getProdListAsync(search));
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error?.response?.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Button
        appearance="link"
        onClick={() => {
          handleOpenEdit();
        }}
      >
        Yenilə
      </Button>
      <div>
        <Modal open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <div>
                <h4>Düzəliş</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />
            <h4 className="modals-h4">{`${rowData?.mehsul_?.mehsul}-${rowData?.cheshid_?.cheshid}-${rowData.kod_?.kod}`}</h4>
            <div className="textfield" style={{ textAlign: "center" }}>
              <form
                id="modal-textfield"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleClose();
                  sendNewData();
                }}
              >
                <TextField
                  autoComplete="off"
                  required
                  style={{ margin: "0.5em 3em", width: "18em" }}
                  label="Kampaniya endirimi"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={editData.kampaniya_endirimi}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      kampaniya_endirimi: Number(e.target.value),
                    });
                  }}
                />

                <TextField
                  required
                  autoComplete="off"
                  style={{ margin: "0.5em 3em", width: "18em" }}
                  label="Qiymət"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={editData.qiymet}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      qiymet: Number(e.target.value),
                    });
                  }}
                />
                <TextField
                  required
                  autoComplete="off"
                  style={{ margin: "0.5em 3em", width: "18em" }}
                  label="Barkod"
                  variant="outlined"
                  size="small"
                  value={editData.barkod}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      barkod: e.target.value,
                    });
                  }}
                />
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button form="modal-textfield" type="submit" appearance="primary">
              Təsdiq et
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Geri qayıt
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditStockModal;
