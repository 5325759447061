import React, { useState, useEffect } from "react";
import { RiLockPasswordLine, RiUserLine } from "react-icons/ri";
import { Alert } from "../../../alert/alert";
import { TextField } from "@mui/material";
import { API } from "../../../baseUrl/baseUrl";
import UsersList from "./UsersList";
import InputAdornment from "@mui/material/InputAdornment";

const Register = () => {
  //User List
  const [usersList, setUsersList] = useState([]);

  const getUsers = async () => {
    const response = await API(`/user`);
    setUsersList(response.data);
  };
  useEffect(() => {
    getUsers();
  }, []);

  //Register

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const postnewuser = async (e: any) => {
    e.preventDefault();
    try {
      const response = await API.post(`/signup`, userData);
      getUsers();
      setUserData({
        email: "",
        password: "",
      });
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      console.log(error);
      alert(error);
      Alert({
        type: "error",
        title: error.response.data,
        placement: "topCenter",
        duration: 5000,
      });
    }
  };

  return (
    <>
      <div className="register-container">
        <div className="header-popup">
          <h5>Yeni hesab yaratmaq: </h5>
        </div>
        <form className="form-register" onSubmit={postnewuser}>
          <div className="user-div-sign">
            <TextField
              autoComplete="off"
              required
              type="email"
              style={{
                width: "18em",
                background: "white",
              }}
              label="E-mail"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="icons-input-sign">
                      <RiUserLine />
                    </span>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              value={userData.email}
              onChange={(e) => {
                setUserData({ ...userData, email: e.target.value });
              }}
            />
          </div>

          <div className="pwd-div-sign">
            <TextField
              autoComplete="off"
              required
              style={{
                width: "18em",
                background: "white",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="icons-input-sign">
                      <RiLockPasswordLine />
                    </span>
                  </InputAdornment>
                ),
              }}
              label="Şifrə"
              variant="outlined"
              size="small"
              value={userData.password}
              onChange={(e) => {
                setUserData({ ...userData, password: e.target.value });
              }}
            />
          </div>
          <button className="popup-submit-btn" type="submit">
            Təsdiqləyin
          </button>
        </form>
      </div>
      <UsersList usersList={usersList} getUsers={getUsers} />
    </>
  );
};

export default Register;
