import React, { useRef, useState } from "react";
import { Button, Modal, SelectPicker } from "rsuite";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
  getProdListAsync,
  productTransferAsync,
} from "../../../../generalUsesReducers/stockSlice";
import { Alert } from "../../../../alert/alert";

const TransferModal = ({ rowData, search }: any) => {
  const dispatch = useAppDispatch();
  const { warehouse_ } = useAppSelector((state) => state.warehouseList);

  const [productTransfer, setProductTransfer] = useState({
    id: 0,
    transfer_say: 0,
    mehsulID: 0,
    cheshidID: 0,
    kodID: 0,
    olchuID: 0,
    destinationWarehouseID: 0,
    barkod: "",
    qiymet: 0,
    kampaniya_endirimi: 0,
  });
  const inputref = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState(false);
  const close = () => setShow(false);
  const open = async () => {
    await setShow(true);
    if (inputref.current) {
      inputref.current.focus();
    }
  };

  const doTransfer = async () => {
    try {
      const response: any = await dispatch(
        productTransferAsync(productTransfer)
      );
      setTimeout(() => {
        dispatch(getProdListAsync(search));
      }, 100);
      Alert({
        type: "success",
        title: response?.payload,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button
        appearance="link"
        onClick={() => {
          open();
          setProductTransfer({
            ...productTransfer,
            id: Number(rowData.id),
            mehsulID: Number(rowData.mehsul_.id),
            cheshidID: Number(rowData.cheshid_.id),
            olchuID: Number(rowData.olchu_.id),
            kodID: Number(rowData.kod_.id),
            barkod: rowData.barkod,
            qiymet: Number(rowData.qiymet),
            kampaniya_endirimi: Number(rowData.kampaniya_endirimi),
          });
        }}
      >
        Transfer
      </Button>
      <Modal open={show} onClose={close} className="transfer-modal">
        <Modal.Header>
          <Modal.Title>
            <div>
              <h4>
                {rowData?.warehouseKey?.warehouse} anbarından digərinə transfer
                et
              </h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5 className="modals-h4">
              {`${rowData?.mehsul_?.mehsul}-${rowData?.cheshid_?.cheshid}-${rowData.kod_?.kod} transfer edilir`}
            </h5>
          </div>
          <div className="transfer-modal">
            <input
              required
              ref={inputref}
              placeholder="miqdarı daxil edin..."
              onChange={(event) =>
                setProductTransfer({
                  ...productTransfer,
                  transfer_say: parseFloat(event.target.value),
                })
              }
            />
            <br />
            <SelectPicker
              className="selectPickerInTransfer"
              data={warehouse_}
              size="lg"
              // searchable={false}
              labelKey="warehouse"
              valueKey="id"
              placeholder="Anbar"
              onSelect={(event) => {
                setProductTransfer({
                  ...productTransfer,
                  destinationWarehouseID: Number(event),
                });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ fontSize: "1.3em" }}>
          <Button
            onClick={() => {
              close();
              doTransfer();
            }}
            appearance="primary"
          >
            Göndər
          </Button>
          <Button onClick={close} appearance="subtle">
            İmtina et
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransferModal;
