import React, { useState, useEffect } from "react";
import { Pagination } from "rsuite";

const Paginations = ({
  totalPage,
  page,
  setPage,
  searchData,
  setSearchData,
}: {
  totalPage: number;
  page: number;
  setPage: any;
  searchData: any;
  setSearchData: Function;
}) => {
  const [limit, setLimit] = useState(10);
  const handleChangeLimit = (dataKey: number) => {
    setPage(1);
    setLimit(dataKey);
    setSearchData({ ...searchData, take: dataKey, skip: 1 });
  };

  useEffect(() => {
    if (searchData.skip !== page) {
      setSearchData({ ...searchData, skip: page });
    }
  }, [page]);

  return (
    <Pagination
      prev
      next
      first
      last
      ellipsis
      boundaryLinks
      maxButtons={6}
      size="xs"
      layout={["total", "-", "limit", "|", "pager", "skip"]}
      total={Number(totalPage)}
      limitOptions={[10, 50, 100]}
      limit={limit}
      activePage={page}
      onChangePage={setPage}
      onChangeLimit={handleChangeLimit}
    />
  );
};

export default Paginations;
