import React, { useState } from "react";
import { API } from "../../../baseUrl/baseUrl";
import { Alert } from "../../../alert/alert";

const DeleteSatishRow = () => {
  const [IDnumber, setIDnumber] = useState<number>();

  const deleteSatish = async () => {
    try {
      const response = await API.delete(`/sales/${IDnumber}`);
      setIDnumber(Number());
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: "Bu ID nömrəsi ilə satış tapılmadı",
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error);
    }
  };
  return (
    <div className="delete-satish-container">
      <h5>Silmək istədiyiniz satışın ID nömrəsini daxil edin:</h5>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <label htmlFor="idInput">
          {" "}
          ID nömrəsi:
          <input
            value={IDnumber === 0 ? "" : IDnumber}
            id="idInput"
            type="number"
            onChange={(e) => setIDnumber(e.target.valueAsNumber)}
          />
        </label>
        <button disabled={!IDnumber} onClick={() => deleteSatish()}>
          Sil
        </button>
      </div>
    </div>
  );
};

export default DeleteSatishRow;
