import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API } from "../baseUrl/baseUrl";
import { RootState } from "../store/store";
import { olchuByCheshidID, getOlchuInterface } from "../interfaces/interfaces";

const getProdinitialState: getOlchuInterface = {
  olchu_: [],
  status: "idle",
};

export const getOlchuListAsync = createAsyncThunk<
  Array<olchuByCheshidID>,
  number
>("getOlchuListAsync/getOlchuListSlicer", async (cheshidID) => {
  try {
    const response = await API.get(`olchu/${cheshidID}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const getOlchuListSlice = createSlice({
  name: "getOlchuListSlicer",
  initialState: getProdinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOlchuListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getOlchuListAsync.fulfilled,
        (state, action: PayloadAction<Array<olchuByCheshidID>>) => {
          state.olchu_ = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getOlchuListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const OlchuList = (state: RootState) => state.olchuList.olchu_;

export default getOlchuListSlice.reducer;
