import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import {
  warehouseInterface,
  getWarehouseInterface,
} from "../interfaces/interfaces";

const getProdinitialState: getWarehouseInterface = {
  warehouse_: [],
  status: "idle",
};

export const getWarehouseListAsync = createAsyncThunk<
  Array<warehouseInterface>
>("getWarehouseListSlicer/getWarehouseList", async () => {
  try {
    const response = await API.get(`/warehouse`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const getWarehouseListSlice = createSlice({
  name: "getWarehouseListSlicer",
  initialState: getProdinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWarehouseListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getWarehouseListAsync.fulfilled,
        (state, action: PayloadAction<Array<warehouseInterface>>) => {
          state.warehouse_ = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getWarehouseListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const WarehouseList = (state: RootState) =>
  state.warehouseList.warehouse_;

export default getWarehouseListSlice.reducer;
