import React, { useState } from "react";
import { Modal } from "rsuite";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { API } from "../../../../baseUrl/baseUrl";
import { TiArrowRightThick } from "react-icons/ti";
import { GiCheckMark } from "react-icons/gi";
import { getMehsulAsync } from "../../../../generalUsesReducers/mehsulSlice";
import { getCheshidListAsync } from "../../../../generalUsesReducers/getCheshidSlice";
import { getKodListAsync } from "../../../../generalUsesReducers/getKodSlice";
import { getOlchuListAsync } from "../../../../generalUsesReducers/getOlchuSlice";
import { getWarehouseListAsync } from "../../../../generalUsesReducers/getWarehouseSlice";

const MarkaPopUp = ({ open, setOpen }: any) => {
  const dispatch = useAppDispatch();
  const { cheshids } = useAppSelector((state) => state.cheshidList);
  const { mehsullar } = useAppSelector((state) => state.mehsulList);
  const { kod_ } = useAppSelector((state) => state.kodList);
  const { olchu_ } = useAppSelector((state) => state.olchuList);

  const [mehsulPost, setMehsulPost] = useState({ mehsul: "" });
  const [cheshidPost, setCheshidPost] = useState({ cheshid: "" });
  const [kodPost, setKodPost] = useState({ kod: "" });
  const [olchuPost, setOlchuPost] = useState({ olchu: "" });
  const [warehousePost, setWarehousePost] = useState({ warehouse: "" });
  const [mehsulToCheshid, setMehsulToCheshid] = useState({
    mehsulID: Number(),
    cheshidID: Number(),
  });
  const [cheshidToKod, setCheshidToKod] = useState({
    cheshidID: Number(),
    kodID: Number(),
  });
  const [cheshidToOlchu, setCheshidToOlchu] = useState({
    olchuID: Number(),
    cheshidID: Number(),
  });

  // emeliyyatin ugurla basha catdigini gostermek uchun useStates
  const [okMehsul, setOkMehsul] = useState(false);
  const [okCheshid, setOkCheshid] = useState(false);
  const [okKod, setOkKod] = useState(false);
  const [okOlchu, setOkOlchu] = useState(false);
  const [okAnbar, setOkAnbar] = useState(false);
  const [oklinkMehChes, setOklinkMehChes] = useState(false);
  const [oklinkChesKod, setOklinkChesKod] = useState(false);
  const [oklinkChesOlchu, setOklinkChesOlchu] = useState(false);

  // yeni markanin melumatlarini post etmek
  const postNewValue = async (e: any, detail: string, data: any) => {
    e.preventDefault();
    try {
      await API.post(`/${detail}`, data);
      if (detail === "mehsul") {
        setMehsulPost({ mehsul: "" });
        setOkMehsul(true);
        dispatch(getMehsulAsync());
        setTimeout(() => {
          setOkMehsul(false);
        }, 5000);
      } else if (detail === "cheshid") {
        setCheshidPost({ cheshid: "" });
        setOkCheshid(true);
        dispatch(getCheshidListAsync(0));
        setTimeout(() => {
          setOkCheshid(false);
        }, 5000);
      } else if (detail === "kod") {
        setKodPost({ kod: "" });
        setOkKod(true);
        dispatch(getKodListAsync(0));
        setTimeout(() => {
          setOkKod(false);
        }, 5000);
      } else if (detail === "olchu") {
        setOkOlchu(true);
        setOlchuPost({ olchu: "" });
        dispatch(getOlchuListAsync(0));
        setTimeout(() => {
          setOkOlchu(false);
        }, 5000);
      } else if (detail === "warehouse") {
        setOkAnbar(true);
        setWarehousePost({ warehouse: "" });
        dispatch(getWarehouseListAsync());
        setTimeout(() => {
          setOkAnbar(false);
        }, 5000);
      }
    } catch (error: any) {
      if (error.response?.data?.includes("has failed the validation")) {
        alert("Məlumat düzgün daxil edilməyib");
        console.log(error);
      } else if (error.response?.data?.includes("Duplicate")) {
        alert("Bu adda məhsul artıq mövcuddur");
        console.log(error);
      } else {
        console.log(error);
        alert("Xəta baş verdi");
      }
    }
  };

  //mehsulu cheshidlemek
  const linkMehsulToCheshid = async () => {
    setOklinkMehChes(false);
    try {
      await API.post(`/mehsul_to_cheshid`, mehsulToCheshid);
      setOklinkMehChes(true);
    } catch (error: any) {
      alert(error);
      console.log(error);
    }
    setTimeout(() => {
      setOklinkMehChes(false);
    }, 5000);
  };

  const linkCheshidToKod = async () => {
    setOklinkChesKod(false);
    try {
      await API.post(`/cheshid_to_kod`, cheshidToKod);
      setOklinkChesKod(true);
    } catch (error: any) {
      alert(error);
      console.log(error);
    }
    setTimeout(() => {
      setOklinkChesKod(false);
    }, 5000);
  };

  const linkCheshidToOlchu = async () => {
    setOklinkChesOlchu(false);
    try {
      await API.post(`/olchu_to_cheshid`, cheshidToOlchu);
      setOklinkChesOlchu(true);
    } catch (error: any) {
      alert(error);
      console.log(error);
    }
    setTimeout(() => {
      setOklinkChesOlchu(false);
    }, 5000);
  };
  //Modal
  const handleClose = () => setOpen(false);
  return (
    <>
      <div>
        <Modal size="md" open={open} onClose={handleClose}>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div className="marka-popup  ms-5">
              <h3 className="pe-5 me-5">Yeni marka yaradın:</h3>
              <section className="marka-popup-yeni-marka">
                <form onSubmit={(e) => postNewValue(e, "mehsul", mehsulPost)}>
                  <div>
                    <input
                      id="mehsul"
                      autoComplete="off"
                      value={mehsulPost?.mehsul}
                      onChange={(e) => {
                        setMehsulPost({
                          ...mehsulPost,
                          mehsul: e.target.value,
                        });
                      }}
                      required
                    />
                    <label htmlFor="mehsul">Məhsul</label>
                    <button type="submit">OK</button>
                    <span className="marka-popup-ok-span">
                      {okMehsul ? <GiCheckMark /> : ""}
                    </span>
                  </div>
                </form>
                <form onSubmit={(e) => postNewValue(e, "cheshid", cheshidPost)}>
                  <div>
                    <input
                      autoComplete="off"
                      id="cheshid"
                      value={cheshidPost?.cheshid}
                      onChange={(e) =>
                        setCheshidPost({
                          ...cheshidPost,
                          cheshid: e.target.value,
                        })
                      }
                      required
                    />
                    <label htmlFor="cheshid">Çeşid</label>
                    <button type="submit">OK</button>
                    <span className="marka-popup-ok-span">
                      {okCheshid ? <GiCheckMark /> : ""}
                    </span>
                  </div>
                </form>
                <form onSubmit={(e) => postNewValue(e, "kod", kodPost)}>
                  <div>
                    <input
                      autoComplete="off"
                      id="kod"
                      value={kodPost?.kod}
                      onChange={(e) =>
                        setKodPost({
                          ...kodPost,
                          kod: e.target.value,
                        })
                      }
                      required
                    />
                    <label htmlFor="kod">Kod</label>
                    <button>OK</button>
                    <span className="marka-popup-ok-span">
                      {okKod ? <GiCheckMark /> : ""}
                    </span>
                  </div>
                </form>
                <form onSubmit={(e) => postNewValue(e, "olchu", olchuPost)}>
                  <div>
                    <input
                      autoComplete="off"
                      id="olchu"
                      value={olchuPost?.olchu}
                      onChange={(e) =>
                        setOlchuPost({
                          ...olchuPost,
                          olchu: e.target.value,
                        })
                      }
                      required
                    />
                    <label htmlFor="olchu">Ölçü</label>
                    <button>OK</button>
                    <span className="marka-popup-ok-span">
                      {okOlchu ? <GiCheckMark /> : ""}
                    </span>
                  </div>
                </form>
                <form
                  onSubmit={(e) => postNewValue(e, "warehouse", warehousePost)}
                >
                  <div>
                    <input
                      autoComplete="off"
                      id="anbar"
                      value={warehousePost?.warehouse}
                      onChange={(e) =>
                        setWarehousePost({
                          ...warehousePost,
                          warehouse: e.target.value,
                        })
                      }
                      required
                    />
                    <label htmlFor="anbar">Anbar/Mağaza</label>
                    <button>OK</button>
                    <span className="marka-popup-ok-span">
                      {okAnbar ? <GiCheckMark /> : ""}
                    </span>
                  </div>
                </form>
                <h3 className="pe-5 me-5 mt-3">Məhsulu çeşidləyin:</h3>
              </section>
              <section className="marka-popup-link-one-other">
                <div>
                  <select
                    onChange={(e) => {
                      if (e.target.value !== "mehsul") {
                        setMehsulToCheshid({
                          ...mehsulToCheshid,
                          mehsulID: parseFloat(e.target.value),
                        });
                      }
                    }}
                  >
                    <option value="mehsul">Məhsul</option>
                    {mehsullar?.map((mehsul: any) => (
                      <option value={mehsul.id} key={mehsul.id}>
                        {mehsul.mehsul}
                      </option>
                    ))}
                  </select>
                  <TiArrowRightThick className="arrow" />
                  <select
                    onChange={(e) => {
                      if (e.target.value !== "cheshid") {
                        setMehsulToCheshid({
                          ...mehsulToCheshid,
                          cheshidID: parseFloat(e.target.value),
                        });
                      }
                    }}
                  >
                    <option value="cheshid">Çeşid</option>
                    {cheshids?.map((cheshid: any) => (
                      <option value={cheshid.id} key={cheshid.id}>
                        {cheshid.cheshid}
                      </option>
                    ))}
                  </select>
                  <button onClick={linkMehsulToCheshid}>OK</button>
                  <span className="marka-popup-ok-span">
                    {oklinkMehChes ? <GiCheckMark /> : ""}
                  </span>
                </div>
                <div>
                  <select
                    onChange={(e) => {
                      if (e.target.value !== "cheshid") {
                        setCheshidToKod({
                          ...cheshidToKod,
                          cheshidID: parseFloat(e.target.value),
                        });
                      }
                    }}
                  >
                    <option value="chesid">Çeşid</option>
                    {cheshids?.map((cheshid: any) => (
                      <option value={cheshid.id} key={cheshid.id}>
                        {cheshid.cheshid}
                      </option>
                    ))}
                  </select>
                  <TiArrowRightThick className="arrow" />
                  <select
                    onChange={(e) => {
                      if (e.target.value !== "kod") {
                        setCheshidToKod({
                          ...cheshidToKod,
                          kodID: parseFloat(e.target.value),
                        });
                      }
                    }}
                  >
                    <option value="kod">Kod</option>
                    {kod_?.map((item: any) => (
                      <option value={item.id} key={item.id}>
                        {item.kod}
                      </option>
                    ))}
                  </select>
                  <button onClick={linkCheshidToKod}>OK</button>
                  <span className="marka-popup-ok-span">
                    {oklinkChesKod ? <GiCheckMark /> : ""}
                  </span>
                </div>
                <div>
                  <select
                    onChange={(e) => {
                      if (e.target.value !== "cheshid") {
                        setCheshidToOlchu({
                          ...cheshidToOlchu,
                          cheshidID: parseFloat(e.target.value),
                        });
                      }
                    }}
                  >
                    <option value="chesid">Çeşid</option>
                    {cheshids?.map((cheshid: any) => (
                      <option value={cheshid.id} key={cheshid.id}>
                        {cheshid.cheshid}
                      </option>
                    ))}
                  </select>
                  <TiArrowRightThick className="arrow" />
                  <select
                    onChange={(e) => {
                      if (e.target.value !== "olchu") {
                        setCheshidToOlchu({
                          ...cheshidToOlchu,
                          olchuID: parseFloat(e.target.value),
                        });
                      }
                    }}
                  >
                    <option value="olchu">Ölçü</option>
                    {olchu_?.map((item: any) => (
                      <option value={item.id} key={item.id}>
                        {item.olchu}
                      </option>
                    ))}
                  </select>
                  <button onClick={linkCheshidToOlchu}>OK</button>
                  <span className="marka-popup-ok-span">
                    {oklinkChesOlchu ? <GiCheckMark /> : ""}
                  </span>
                </div>
              </section>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default MarkaPopUp;
