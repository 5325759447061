import React, { useState } from "react";
import { TextField } from "@mui/material";
import { API } from "../../../baseUrl/baseUrl";
import { Alert } from "../../../alert/alert";
import { useAppDispatch } from "../../../store/hooks";
import { getRoleListAsync } from "../../../generalUsesReducers/getRoleSlicer";

const CreateRole = () => {
  const dispatch = useAppDispatch();
  const [roleData, setRoleData] = useState("");

  const postRole = async (e: any) => {
    e.preventDefault();
    try {
      const response = await API.post(`/role`, { role: roleData });
      setRoleData("");
      dispatch(getRoleListAsync());
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error.response?.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error);
    }
  };
  return (
    <form onSubmit={postRole}>
      <div className="create-role-div">
        <h5>Yeni kateqoriya yaratmaq: </h5>
        <div className="create-role-sub-div">
          <TextField
            style={{
              width: "18em",
              background: "white",
            }}
            required
            autoComplete="off"
            label="yeni kateqoriya"
            variant="outlined"
            size="small"
            value={roleData}
            onChange={(e) => {
              setRoleData(e.target.value);
            }}
          />
        </div>
        <div className="create-role-sub-div">
          <button type="submit" className="create-role-btn">
            Yadda saxla
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateRole;
