import React, { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { getRoleListAsync } from "../../generalUsesReducers/getRoleSlicer";

const AdminPanel = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRoleListAsync());
  }, [dispatch]);

  return (
    <div className="admin-panel-side-bar">
      <div className="admin-panel-div">
        <nav className="admin-panel-nav">
          <NavLink to="roles" className="admin-side-navlink">
            İstifadəçi kateqoriyaları
          </NavLink>
          <NavLink to="registration" className="admin-side-navlink">
            İstifadəçi hesabları
          </NavLink>
          <NavLink to="warehouses" className="admin-side-navlink">
            Mağaza və anbarlar
          </NavLink>
          <NavLink to="change_product_value" className="admin-side-navlink">
            Məhsullara düzəlişlər
          </NavLink>
          <NavLink to="delete_sales_row" className="admin-side-navlink">
            Satışın silinməsi
          </NavLink>
        </nav>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default AdminPanel;
