import React, { useEffect, useState } from "react";
import TableTarixche from "./components/TableTarixche";
import { API } from "../../baseUrl/baseUrl";
import qs from "qs";
import SearchTarixche from "./components/SearchTarixche";
import TarixcheFooter from "./components/TarixcheFooter";

import { useAuth } from "../../Sign/Auth";
import { useAppSelector } from "../../store/hooks";

const formatDate = (date: any) => {
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
};

const SatishTarixchesi = () => {
  const { decode } = useAuth();

  const date2 = new Date();
  const date3 = new Date();
  date2.setDate(date2.getDate() + 1);

  const myDate2 = formatDate(date2);
  const myDate3 = formatDate(date3);

  const [search, setSearch] = useState({
    mehsulID: 0,
    cheshidID: 0,
    kodID: 0,
    warehouseID: 0,
    take: 10,
    skip: 1,
    date1: myDate3,
    date2: myDate2,
  });

  const [sales, setSales] = useState([
    {
      id: Number(),
      say: Number(),
      kampaniya_endirimi: String(),
      qiymet: String(),
      tarix: String(),
      stockID: Number(),
      stocks: {
        mehsulID: Number(),
        cheshidID: Number(),
        kodID: Number(),
        warehouseID: Number(),
        olchuID: Number(),
        barkod: String(),
        cheshid_: {
          cheshid: String(),
        },
        mehsul_: {
          mehsul: String(),
        },
        kod_: {
          kod: String(),
        },
        olchu_: {
          olchu: String(),
        },
        warehouseKey: {
          warehouse: String(),
        },
      },
    },
  ]);
  const [dailyCash, setDailyCash] = useState<any>([]);
  const [special, setSpecial] = useState<any>([]);
  const [endirim, setEndirim] = useState(0);
  const getData = async () => {
    try {
      const result = await API(`/sales?${qs.stringify(search)}`);
      const response = await API(`/sales/endirim?${qs.stringify(search)}`);
      setSales(result.data);
      setDailyCash(result?.data[2]);
      setSpecial(
        result?.data[0]
          .filter((item: any) => item?.stocks?.mehsul_?.mehsul === "XIRDAVAT-F")
          ?.map((i: any) => Number(i.say) * Number(i.qiymet))
          .reduce((accumulator: number, item: number) => accumulator + item, 0)
      );
      setEndirim(response?.data[2]);
    } catch (error) {
      console.log(error);
    }
  };

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (decode?.warehouseID !== undefined) {
      setSearch({
        ...search,
        warehouseID: decode?.warehouseID,
      });
      console.log(search.warehouseID, decode);
    }
  }, [decode]);

  useEffect(() => {
    if (search.warehouseID) {
      getData();
    } else if (
      search.warehouseID === 0 &&
      decode &&
      decode?.warehouseID === undefined
    ) {
      getData();
    }
  }, [search, page]);

  const { permissionsList } = useAppSelector((state) => state.permissionsList);
  const permissions = permissionsList.map((item) => item.permission);
  return (
    <div style={{ marginTop: "5em" }}>
      <div>
        <SearchTarixche
          permissions={permissions}
          searchItems={search}
          setSearchItems={setSearch}
          setPage={setPage}
        />
      </div>
      <div>
        <TableTarixche
          permissions={permissions}
          searchItems={search}
          setSearchItems={setSearch}
          page={page}
          setPage={setPage}
          sales={sales}
          getData={getData}
        />
        <TarixcheFooter
          dailyCash={dailyCash}
          special={special}
          endirim={endirim}
        />
      </div>
    </div>
  );
};

export default SatishTarixchesi;
