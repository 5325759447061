import React from "react";
import { Alert } from "../../../alert/alert";
import { API } from "../../../baseUrl/baseUrl";
import PermissionPage from "./PermissionPage";
import { FaTrashRestore } from "react-icons/fa";
import { getRoleListAsync } from "../../../generalUsesReducers/getRoleSlicer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

const RolesList = () => {
  const dispatch = useAppDispatch();
  const { roles } = useAppSelector((state) => state.roleList);
  const deleteRole = async (id: number) => {
    try {
      const response = await API.delete(`/role/${id}`);
      dispatch(getRoleListAsync());
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      if (error.response?.data.includes("a foreign key constraint fails")) {
        Alert({
          type: "error",
          title:
            "İcazələrin tamamilə ləğv edildiyindən və kateqoriyanın hər hansı istifadəçiyə bağlı olmadığından əmin olun",
          placement: "topCenter",
          duration: 5000,
        });
      } else {
        Alert({
          type: "error",
          title: error.response?.data,
          placement: "topCenter",
          duration: 5000,
        });
      }
      console.log(error);
    }
  };
  return (
    <>
      <div className="role-page">
        <h3>İstifadəçi kateqoriyaları:</h3>
        <table className="role-table">
          <tbody>
            {roles?.map((role: any, index: number) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{role.role && role.role.toUpperCase()}</td>
                <td>
                  <PermissionPage roleName={role?.role} roleID={role?.id} />
                </td>
                <td>
                  <button
                    className="role-wh-delete-btn"
                    onClick={() => deleteRole(role.id)}
                  >
                    <FaTrashRestore className="delete-icons" title="sil" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RolesList;
