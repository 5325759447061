import React, { useState, useRef, useEffect } from "react";
import Kassa from "./components/Kassa";
import { API } from "../../baseUrl/baseUrl";
import { Alert } from "../../alert/alert";
import KassaFooter from "./components/KassaFooter";
import Cookies from "js-cookie";
import qs from "qs";
import jwtDecode from "jwt-decode";

function Satish() {
  const { warehouseID } = jwtDecode(Cookies.get("accessCookie"));
  const [totalPrice, setTotalPrice] = useState(0);
  const [barkodErr, setBarkodErr] = useState("");
  const [kassa, setKassa] = useState([]);
  const [endirim, setEndirim] = useState(Number());
  const [qaliqPul, setQaliqPul] = useState(0);
  const [odenildi, setOdenildi] = useState(Number());
  const barkodRef = useRef();
  const [barcode, setBarcode] = useState("");
  const [warning, setWarning] = useState(false);

  const getBarcodeManually = async (e) => {
    e.preventDefault();
    try {
      const response = await API(
        `/stock_for_sale?${qs.stringify({
          barkod: barcode,
          warehouseID,
        })}`
      );
      if (
        response.data !== undefined &&
        barcode.length > 0 &&
        response.data.barkod === barcode
      ) {
        const prod = {
          ...response.data,
          say: 1,
          mebleg: response.data.qiymet - response.data.kampaniya_endirimi,
        };
        if (kassa.map((item) => item.barkod).includes(response.data.barkod)) {
          setKassa(
            kassa.map((item) => {
              if (item.barkod === response.data.barkod) {
                const sayi = item.say + 1 * 1;
                const meblegi = (item.qiymet - item.kampaniya_endirimi) * sayi;
                return { ...item, say: sayi, mebleg: meblegi };
              } else {
                return item;
              }
            })
          );
          setBarcode("");
          setBarkodErr("");
          setOdenildi(0);
          setQaliqPul(0);
          barkodRef.current.focus();
        } else {
          setKassa([...kassa, prod]);
          setBarcode("");
          setBarkodErr("");
          barkodRef.current.focus();
        }
      } else if (barcode.length === 0) {
        setBarkodErr("Barkodu daxil edin!");
      }
    } catch (error) {
      console.log(error);
      setBarkodErr("Barkod səhvdir yaxud məhsul bu mağazada yoxdur!");
    }
  };

  const autoBarcodEntry = async (e) => {
    try {
      const response = await API(
        `/stock_for_sale?${qs.stringify({
          barkod: e.clipboardData.getData("Text"),
          warehouseID,
        })}`
      );
      const prod = {
        ...response.data,
        say: 1,
        mebleg: response.data.qiymet - response.data.kampaniya_endirimi,
      };
      if (kassa.map((item) => item.barkod).includes(prod.barkod)) {
        setKassa(
          kassa.map((item) => {
            if (item.barkod === response.data.barkod) {
              const sayi = item.say * 1 + 1;
              const meblegi = (item.qiymet - item.kampaniya_endirimi) * sayi;
              return { ...item, say: sayi, mebleg: meblegi };
            } else {
              return item;
            }
          })
        );
        setBarkodErr("");
        barkodRef.current.focus();
        setOdenildi(0);
        setQaliqPul(0);
        setTimeout(() => {
          setBarcode("");
        }, 10);
      } else {
        setKassa([...kassa, prod]);
        setBarkodErr("");
        setTimeout(() => {
          setBarcode("");
        }, 100);
      }
    } catch (error) {
      setBarkodErr("Barkod səhvdir yaxud məhsul bu mağazada yoxdur!");
      console.log(error);
    }
  };

  const final = kassa.map((item) => ({
    say: Number(item.say),
    kampaniya_endirimi: Number(item.kampaniya_endirimi),
    qiymet: Number(item.qiymet),
    stockID: Number(item.id),
    barkod: item.barkod,
    warehouseID: item.warehouseID,
  }));

  const [isSale, setIsSale] = useState(false);

  const completeSale = async () => {
    setIsSale(false);
    try {
      setIsSale(true);
      const response = await API.post(`/sales`, final);
      await API.post(`/endirim`, {
        endirim: Number(endirim),
        warehouseID: warehouseID,
      });
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
      setKassa([]);
      setTotalPrice(0);
      setEndirim(0);
      setQaliqPul(0);
      setOdenildi(0);
    } catch (error) {
      Alert({
        type: "error",
        title: error.response.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error);
    }
    barkodRef.current.focus();
    setIsSale(false);
  };

  useEffect(() => {
    if (barkodRef.current) {
      barkodRef.current.focus();
    }
  }, []);
  return (
    <>
      <div className="container-satish-form">
        <div className="kassa-table-container">
          <Kassa
            setTotalPrice={setTotalPrice}
            kassa={kassa}
            setKassa={setKassa}
            endirim={endirim}
            setEndirim={setEndirim}
            setQaliqPul={setQaliqPul}
            setOdenildi={setOdenildi}
            setWarning={setWarning}
            totalPrice={totalPrice}
          />
        </div>
        <KassaFooter
          endirim={endirim}
          setEndirim={setEndirim}
          qaliqPul={qaliqPul}
          setQaliqPul={setQaliqPul}
          odenildi={odenildi}
          setOdenildi={setOdenildi}
          totalPrice={totalPrice}
          barcode={barcode}
          setBarcode={setBarcode}
          barkodErr={barkodErr}
          completeSale={completeSale}
          getBarcodeManually={getBarcodeManually}
          autoBarcodEntry={autoBarcodEntry}
          barkodRef={barkodRef}
          warning={warning}
          setWarning={setWarning}
          isSale={isSale}
        />
      </div>
    </>
  );
}

export default Satish;
