import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import {
  getCheshidInterface,
  cheshidByMehsulID,
} from "../interfaces/interfaces";

const getProdinitialState: getCheshidInterface = {
  cheshids: [],
  status: "idle",
};

export const getCheshidListAsync = createAsyncThunk<
  Array<cheshidByMehsulID>,
  number
>("getCheshidListAsync/getCheshidListSlicer", async (mehsulID) => {
  try {
    const response = await API.get(`/mehsul_to_cheshid/${mehsulID}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const getCheshidListSlice = createSlice({
  name: "getCheshidListSlicer",
  initialState: getProdinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCheshidListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getCheshidListAsync.fulfilled,
        (state, action: PayloadAction<Array<cheshidByMehsulID>>) => {
          state.cheshids = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getCheshidListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});
export const CheshidList = (state: RootState) => state.cheshidList.cheshids;
export default getCheshidListSlice.reducer;
