import React, { useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { Alert } from "../../../alert/alert";
import { API } from "../../../baseUrl/baseUrl";
import { useAppDispatch } from "../../../store/hooks";
import { getMehsulAsync } from "../../../generalUsesReducers/mehsulSlice";
import { getCheshidListAsync } from "../../../generalUsesReducers/getCheshidSlice";
import { getKodListAsync } from "../../../generalUsesReducers/getKodSlice";
import { getOlchuListAsync } from "../../../generalUsesReducers/getOlchuSlice";

const ChangeProdValue = () => {
  const dispatch = useAppDispatch();

  const { mehsullar } = useAppSelector((state) => state.mehsulList);
  const { cheshids } = useAppSelector((state) => state.cheshidList);
  const { kod_ } = useAppSelector((state) => state.kodList);
  const { olchu_ } = useAppSelector((state) => state.olchuList);

  const [mehsulValue, setMehsulValue] = useState({
    id: Number(),
    mehsul: "",
  });
  const [cheshidValue, setCheshidValue] = useState({
    id: Number(),
    cheshid: "",
  });
  const [kodValue, setKodValue] = useState({
    id: Number(),
    kod: "",
  });
  const [olchuValue, setOlchuValue] = useState({
    id: Number(),
    olchu: "",
  });

  const handleChange = async (detail: string, data: any) => {
    try {
      const response = await API.patch(`/${detail}`, data);
      if (detail === "mehsul") {
        dispatch(getMehsulAsync());
      } else if (detail === "cheshid") {
        dispatch(getCheshidListAsync(0));
      } else if (detail === "kod") {
        dispatch(getKodListAsync(0));
      } else if (detail === "olchu") {
        dispatch(getOlchuListAsync(0));
      }

      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error.response.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error);
    }
  };

  const handledelete = async (value: string, id: number) => {
    try {
      if (id !== 0) {
        const res = await API.delete(`/${value}/${id}`);
        if (value === "mehsul") {
          setMehsulValue({ id: Number(), mehsul: "" });
          dispatch(getMehsulAsync());
        } else if (value === "cheshid") {
          setCheshidValue({ id: Number(), cheshid: "" });
          dispatch(getCheshidListAsync(0));
        } else if (value === "kod") {
          setKodValue({ id: Number(), kod: "" });
          dispatch(getKodListAsync(0));
        } else if (value === "olchu") {
          setOlchuValue({ id: Number(), olchu: "" });
          dispatch(getOlchuListAsync(0));
        }
        Alert({
          type: "success",
          title: res?.data,
          placement: "bottomCenter",
          duration: 2000,
        });
      }
    } catch (error: any) {
      if (error.response?.data.includes("a foreign key constraint fails")) {
        Alert({
          type: "error",
          title: "Stokda olan məhsullara aid dəyərlərin silinməsi mümküm deyil",
          placement: "topCenter",
          duration: 5000,
        });
      } else {
        Alert({
          type: "error",
          title: error.response?.data,
          placement: "topCenter",
          duration: 5000,
        });
      }
    }
  };
  return (
    <div className="admin-panel-changins" style={{ margin: "3em" }}>
      <section className="sides">
        <section>
          <h4>Məhsullar</h4>
          <select
            onChange={(e) => {
              if (e.target.value !== "mehsul") {
                const mehsul: any = mehsullar.find(
                  (item) => item.id === Number(e.target.value)
                );
                setMehsulValue({
                  ...mehsulValue,
                  id: mehsul.id,
                  mehsul: mehsul.mehsul,
                });
              } else {
                setMehsulValue({
                  id: Number(),
                  mehsul: "",
                });
              }
            }}
          >
            <option value="mehsul">Məhsul</option>
            {mehsullar?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.mehsul}
              </option>
            ))}
          </select>
          <div>
            <input
              disabled={mehsulValue.id === 0}
              onChange={(e) => {
                setMehsulValue({ ...mehsulValue, mehsul: e.target.value });
              }}
              value={mehsulValue.mehsul}
            />
            <button
              disabled={mehsulValue.id === 0}
              onClick={() => handleChange("mehsul", mehsulValue)}
            >
              Düzəliş et
            </button>
            <button
              disabled={mehsulValue.id === 0}
              onClick={() => handledelete("mehsul", mehsulValue.id)}
            >
              Sil
            </button>
          </div>
        </section>
        <section>
          <h4>Çeşidlər</h4>
          <select
            onChange={(e) => {
              if (e.target.value !== "cheshid") {
                const cheshidler: any = cheshids?.find(
                  (item) => item.id === Number(e.target.value)
                );
                setCheshidValue({
                  ...cheshidValue,
                  id: Number(cheshidler.id),
                  cheshid: cheshidler.cheshid,
                });
              } else {
                setCheshidValue({
                  id: Number(),
                  cheshid: "",
                });
              }
            }}
          >
            <option value="cheshid">Çeşid</option>
            {cheshids?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.cheshid}
              </option>
            ))}
          </select>
          <div>
            <input
              disabled={cheshidValue.id === 0}
              onChange={(e) => {
                setCheshidValue({ ...cheshidValue, cheshid: e.target.value });
              }}
              value={cheshidValue.cheshid}
            />
            <button
              disabled={cheshidValue.id === 0}
              onClick={() => handleChange("cheshid", cheshidValue)}
            >
              Düzəliş et
            </button>
            <button
              disabled={cheshidValue.id === 0}
              onClick={() => handledelete("cheshid", cheshidValue.id)}
            >
              Sil
            </button>
          </div>
        </section>
      </section>
      <section className="sides">
        <section>
          <h4>Kodlar</h4>
          <select
            onChange={(e) => {
              if (e.target.value !== "kod") {
                const kodlar: any = kod_?.find(
                  (item) => item.id === Number(e.target.value)
                );
                setKodValue({
                  ...kodValue,
                  id: kodlar.id,
                  kod: kodlar.kod,
                });
              } else {
                setKodValue({
                  id: Number(),
                  kod: "",
                });
              }
            }}
          >
            <option value="kod">Kod</option>
            {kod_?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.kod}
              </option>
            ))}
          </select>
          <div>
            <input
              disabled={kodValue.id === 0}
              onChange={(e) => {
                setKodValue({ ...kodValue, kod: e.target.value });
              }}
              value={kodValue.kod}
            />

            <button
              disabled={kodValue.id === 0}
              onClick={() => handleChange("kod", kodValue)}
            >
              Düzəliş et
            </button>

            <button
              disabled={kodValue.id === 0}
              onClick={() => handledelete("kod", kodValue.id)}
            >
              Sil
            </button>
          </div>
        </section>
        <section>
          <h4>Ölçülər</h4>
          <select
            onChange={(e) => {
              if (e.target.value !== "olchu") {
                const olchuler: any = olchu_?.find(
                  (item) => item.id === Number(e.target.value)
                );
                setOlchuValue({
                  ...olchuValue,
                  id: Number(olchuler.id),
                  olchu: olchuler.olchu,
                });
              } else {
                setOlchuValue({
                  id: Number(),
                  olchu: "",
                });
              }
            }}
          >
            <option value="olchu">Ölçü</option>
            {olchu_?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.olchu}
              </option>
            ))}
          </select>
          <div>
            <input
              disabled={olchuValue.id === 0}
              onChange={(e) => {
                setOlchuValue({ ...olchuValue, olchu: e.target.value });
              }}
              value={olchuValue.olchu}
            />

            <button
              disabled={olchuValue.id === 0}
              onClick={() => handleChange("olchu", olchuValue)}
            >
              Düzəliş et
            </button>

            <button
              disabled={olchuValue.id === 0}
              onClick={() => handledelete("olchu", olchuValue.id)}
            >
              Sil
            </button>
          </div>
        </section>
      </section>
    </div>
  );
};

export default ChangeProdValue;
