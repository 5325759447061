import React, { useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { API } from "../../../../baseUrl/baseUrl";
import { productInterface } from "../../../../interfaces/interfaces";
import { SelectPicker } from "rsuite";

const SearchSelect = ({
  product,
  setProduct,
}: {
  product: productInterface;
  setProduct: any;
}) => {
  const { mehsullar } = useAppSelector((state) => state.mehsulList);
  const [cheshids, setCheshids] = useState([]);
  const [kod_, setKod] = useState([]);
  const [olchu_, setOlchu] = useState([]);
  const { warehouse_ } = useAppSelector((state) => state.warehouseList);

  const selectMehsulID = async (id: number) => {
    try {
      const response = await API.get(`/mehsul_to_cheshid/${id}`);
      setCheshids(response.data);
      setProduct({
        ...product,
        mehsulID: id,
      });
      console.log(product);
    } catch (error) {
      console.log(error);
    }
  };

  const selectCheshidID = async (id: number) => {
    try {
      const response1 = await API.get(`/cheshid_to_kod/${id}`);
      setKod(response1.data);
      const response2 = await API.get(`/olchu/${id}`);
      setOlchu(response2.data);
      setProduct({
        ...product,
        cheshidID: id,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const selectKodID = (id: number) => {
    setProduct({
      ...product,
      kodID: id,
    });
  };
  const selectOlchuID = (id: number) => {
    setProduct({
      ...product,
      olchuID: id,
    });
  };

  const selectWarehouseID = (id: number) => {
    setProduct({
      ...product,
      warehouseID: id,
    });
  };
  return (
    <div>
      <section className="alish-selects-section">
        <div className="alish-input-div">
          <SelectPicker
            style={{ width: 316 }}
            data={mehsullar}
            size="lg"
            labelKey="mehsul"
            valueKey="id"
            placeholder="Məhsul"
            onSelect={(event) => selectMehsulID(event)}
            onClean={() => {
              setCheshids([]);
              setKod([]);
              setOlchu([]);
            }}
          />
        </div>

        <div className="alish-input-div">
          <SelectPicker
            disabled={!cheshids?.length}
            style={{ width: 316 }}
            data={cheshids}
            size="lg"
            labelKey="cheshid"
            valueKey="id"
            placeholder="Çeşid"
            onSelect={(event) => selectCheshidID(event)}
            onClean={() => {
              setKod([]);
              setOlchu([]);
            }}
          />
        </div>

        <div className="alish-input-div">
          <SelectPicker
            disabled={!kod_?.length}
            style={{ width: 316 }}
            data={kod_}
            size="lg"
            labelKey="kod"
            valueKey="id"
            placeholder="Kod"
            onSelect={(event) => selectKodID(event)}
          />
        </div>

        <div>
          <SelectPicker
            disabled={!olchu_?.length}
            style={{ width: 316, color: "black" }}
            data={olchu_}
            size="lg"
            labelKey="olchu"
            valueKey="id"
            placeholder="Ölçü"
            onSelect={(event) => selectOlchuID(event)}
          />
        </div>

        <div className="alish-input-div">
          <SelectPicker
            style={{ width: 316 }}
            data={warehouse_}
            size="lg"
            labelKey="warehouse"
            valueKey="id"
            placeholder="Mağaza"
            onSelect={(event) => selectWarehouseID(event)}
          />
        </div>
      </section>
    </div>
  );
};

export default SearchSelect;
