import React from "react";
import { Button, Modal } from "rsuite";
import RemindIcon from "@rsuite/icons/legacy/Remind";

const DeleteModal = ({ rowData, handleDelete }: any) => {
  const [openDM, setOpenDM] = React.useState(false);
  const handleOpen = () => {
    setOpenDM(true);
  };
  const handleClose = () => setOpenDM(false);

  return (
    <div>
      <Button appearance="link" onClick={handleOpen}>
        Sil
      </Button>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={openDM}
        onClose={handleClose}
        size="xs"
      >
        <Modal.Body>
          <RemindIcon style={{ color: "#ffb300", fontSize: 24 }} />
          <h5 style={{ display: "inline-block", fontWeight: "normal" }}>
            Silmək istədiyinizə əminsiz?
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              handleDelete(rowData.id);
              handleClose();
            }}
            appearance="primary"
          >
            Sil
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            İmtina et
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteModal;
