import { TextField } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { searchItemsFakturaInterface } from "../../../interfaces/interfaces";
import { SelectPicker } from "rsuite";
import IMPORT from "../../../images/IMPORT.png";
import { useAppDispatch } from "../../../store/hooks";
import { getCheshidListAsync } from "../../../generalUsesReducers/getCheshidSlice";
import { getKodListAsync } from "../../../generalUsesReducers/getKodSlice";

const SearchBar = ({
  searchItems,
  setSearchItems,
  setPage,
}: {
  searchItems: searchItemsFakturaInterface;
  setSearchItems: Function;
  setPage: Function;
}) => {
  const dispatch = useAppDispatch();
  const { mehsullar } = useAppSelector((state) => state.mehsulList);
  const { cheshids } = useAppSelector((state) => state.cheshidList);
  const { kod_ } = useAppSelector((state) => state.kodList);

  return (
    <>
      <div style={{ marginTop: "4em" }}>
        <section className="tableAlish-select-section">
          <img className="import-logo" src={IMPORT} alt="import-logo"></img>
          <div>
            <SelectPicker
              style={{ width: 180, marginLeft: "1em" }}
              data={mehsullar}
              size="lg"
              labelKey="mehsul"
              valueKey="id"
              placeholder="Məhsul"
              onSelect={(event: any) => {
                setSearchItems({
                  ...searchItems,
                  mehsulID: event,
                  skip: 1,
                });
                setPage(1);
                dispatch(getCheshidListAsync(event));
              }}
              onClean={() => {
                setSearchItems({
                  ...searchItems,
                  mehsulID: 0,
                  skip: 1,
                });
                setPage(1);
                dispatch(getCheshidListAsync(0));
              }}
            />
          </div>

          <SelectPicker
            style={{ width: 180, marginLeft: "1em" }}
            data={cheshids}
            size="lg"
            labelKey="cheshid"
            valueKey="id"
            placeholder="Çeşid"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                cheshidID: event,
                skip: 1,
              });
              setPage(1);
              dispatch(getKodListAsync(event));
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                cheshidID: 0,
                skip: 1,
              });
              setPage(1);
              dispatch(getKodListAsync(0));
            }}
          />
          <SelectPicker
            style={{ width: 180, marginLeft: "1em" }}
            data={kod_}
            size="lg"
            labelKey="kod"
            valueKey="id"
            placeholder="Kod"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                kodID: event,
                skip: 1,
              });
              setPage(1);
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                kodID: 0,
                skip: 1,
              });
              setPage(1);
            }}
          />

          <TextField
            style={{ margin: "0 1em", width: "12em", background: "white" }}
            label="faktura"
            variant="outlined"
            type="search"
            size="small"
            value={searchItems.faktura}
            onChange={(e) => {
              setSearchItems({
                ...searchItems,
                faktura: e.target.value,
                skip: 1,
              });
              setPage(1);
            }}
          />

          <TextField
            style={{ marginRight: "0.5em", width: "12em", background: "white" }}
            label="tarixdən"
            type="date"
            variant="outlined"
            size="small"
            value={searchItems.date1}
            onChange={(e) => {
              setSearchItems({
                ...searchItems,
                date1: e.target.value,
                skip: 1,
              });
              setPage(1);
            }}
          />
          <TextField
            style={{ margin: "0 0.5em", width: "12em", background: "white" }}
            label="tarixədək"
            type="date"
            variant="outlined"
            size="small"
            value={searchItems.date2}
            onChange={(e) => {
              setSearchItems({
                ...searchItems,
                date2: e.target.value,
                skip: 1,
              });
              setPage(1);
            }}
          />
        </section>
      </div>
    </>
  );
};
export default SearchBar;
