import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import {
  fakturaListInterface,
  FakturaInterface,
  searchItemsFakturaInterface,
} from "../interfaces/interfaces";
import qs from "qs";

const getFakturaInitialState: fakturaListInterface = {
  faktura: [[], 0],
  status: "idle",
};

export const getFakturaListAsync = createAsyncThunk<
  [Array<FakturaInterface>, number],
  searchItemsFakturaInterface
>("getFakturaListAsync/fakturaListSlicer", async (urlParams) => {
  try {
    const response = await API.get(`/faktura?${qs.stringify(urlParams)}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const getFakturaListSlice = createSlice({
  name: "getFakturaListSlicer",
  initialState: getFakturaInitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFakturaListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getFakturaListAsync.fulfilled,
        (state, action: PayloadAction<[Array<FakturaInterface>, number]>) => {
          state.faktura = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getFakturaListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});
export const FakturaList = (state: RootState) => state.fakturaList.faktura;
export default getFakturaListSlice.reducer;
