import React, { createContext, useContext, useEffect, useState } from "react";
import GetCookie from "../cookies/getCookie";
import { decodeType } from "../interfaces/interfaces";
import jwtDecode from "jwt-decode";

interface AuthType {
  decode: decodeType | null;
  token: string | undefined;
  signin: (cookie: string, cb: () => void) => void;
  signout: (cb: () => void) => void;
}

export const AuthContext = createContext<AuthType>({
  decode: null,
  token: "",
  signin: () => {},
  signout: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string | undefined>(
    GetCookie("accessCookie")
  );
  const [decode, setDecode] = useState<decodeType | null>(null);

  useEffect(() => {
    const cookie = GetCookie("accessCookie");
    if (cookie) {
      try {
        const decoded = jwtDecode<decodeType>(cookie);
        setDecode(decoded);
      } catch (error) {
        console.error("Failed to decode JWT:", error);
        setDecode(null);
      }
    }
  }, []);

  const signin = (cookie: string, cb: () => void) => {
    setToken(cookie);
    try {
      const decoded = jwtDecode<decodeType>(cookie);
      setDecode(decoded);
    } catch (error) {
      console.error("Failed to decode JWT on signin:", error);
      setDecode(null);
    }
    cb();
  };

  const signout = (cb: () => void) => {
    setToken(undefined);
    setDecode(null);
    cb();
  };

  const value = { decode, token, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
