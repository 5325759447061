import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import { Alert } from "../alert/alert";
import qs from "qs";
import {
  ProductListInterface,
  Product,
  CreateProductInterface,
  stockRowDataInterface,
  searchParametersInterface,
} from "../interfaces/interfaces";
import { AxiosError } from "axios";
import { FakturaInterface } from "../interfaces/interfaces";

const getProdinitialState: ProductListInterface = {
  products: [[], 0],
  status: "idle",
};

export const deleteStockAsync = createAsyncThunk<object, number>(
  "getProdListSlicer/deleteStock",
  async (id) => {
    try {
      const response = await API.delete(`/stock/${id}`);
      Alert({
        type: "success",
        title: response.data,
        placement: "bottomCenter",
        duration: 2000,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getProdListAsync = createAsyncThunk<
  [Array<Product>, number],
  searchParametersInterface
>("getProdListSlicer/getProductList", async (urlParams) => {
  try {
    const response = await API.get(`/stock?${qs.stringify(urlParams)}`);
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      Alert({
        type: "error",
        title: err.response?.data,
        placement: "bottomCenter",
        duration: 5000,
      });
      return err.response?.data;
    }
  }
});

export const productTransferAsync = createAsyncThunk<
  stockRowDataInterface,
  Object
>("productTransferSlicer/postProductList", async (data) => {
  try {
    const response = await API.post(`/transfer`, data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const createProdManAsync = createAsyncThunk<
  CreateProductInterface,
  Object
>("postProdListSlicer/postProductList", async (data) => {
  try {
    const response = await API.post(`/stock`, data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const createFakturaAsync = createAsyncThunk<FakturaInterface, Object>(
  "postProdListSlicer/createFaktura",
  async (data) => {
    try {
      const response = await API.post(`/faktura`, data);
      Alert({
        type: "success",
        title: response.data,
        placement: "bottomCenter",
        duration: 2000,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getProdListSlice = createSlice({
  name: "getProdListSlicer",
  initialState: getProdinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getProdListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getProdListAsync.fulfilled,
        (state, action: PayloadAction<[Array<Product>, number]>) => {
          state.products = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getProdListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const productLists = (state: RootState) => state.productList.products;

export default getProdListSlice.reducer;
