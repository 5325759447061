import axios from "axios";
import { apiConfig } from "./apiConfig";

const api = apiConfig();

axios.defaults.withCredentials = true;

export const API = axios.create({
  baseURL: api,
  headers: { "Content-Type": "application/json " },
});
