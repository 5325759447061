import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const InitialValue = {
  load: false,
};

const LoadSlice = createSlice({
  name: "loadingControl",
  initialState: InitialValue,
  reducers: {
    controlLoad(state, action: PayloadAction<boolean>) {
      state.load = action.payload;
    },
  },
});
export const { controlLoad } = LoadSlice.actions;

export default LoadSlice.reducer;
