import React from "react";
import { NavLink } from "react-router-dom";

const UPS = () => {
  return (
    <div style={{ margin: "6em", textAlign: "center" }}>
      <h1>404</h1>
      <h2>Axtardığınız səhifə tapılmadı...</h2>
      <NavLink to={`/`}>
        <h4>Ana səhifəyə qayıdın</h4>
      </NavLink>
    </div>
  );
};

export default UPS;
