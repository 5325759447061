import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { API } from "../baseUrl/baseUrl";
import {
  getpermissionlistInterface,
  permissionlistInterface,
} from "../interfaces/interfaces";

export const getListPermissionAsync = createAsyncThunk<
  Array<permissionlistInterface>,
  number
>("getPermissionAsync/permissionList", async (userID) => {
  try {
    const response = await API.get(`/permission_list/${userID}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

const initialState: getpermissionlistInterface = {
  permissionsList: [],
  status: "idle",
};
const getPermissionsSlice = createSlice({
  name: "listOfPermission",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getListPermissionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getListPermissionAsync.fulfilled,
        (state, action: PayloadAction<Array<permissionlistInterface>>) => {
          state.permissionsList = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getListPermissionAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default getPermissionsSlice.reducer;
