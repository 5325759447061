import React from "react";
import ModalUser from "./ModalUser";

const UsersList = ({ usersList }: any) => {
  return (
    <div className="user-list-div">
      <h3>Hesablar:</h3>
      <table className="tablo-user-list">
        <tbody>
          {usersList?.map((user: any, index: number) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.email}</td>
              <td>
                <ModalUser email={user.email} id={user.id} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersList;
